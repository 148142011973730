
import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faArrowRight, faPencil, faCheck, faTimes, faHourglass, faCalendarWeek, faExclamationTriangle, faTrash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowLeft, faArrowRight, faPencil, faCheck, faTimes, faHourglass, faCalendarWeek, faExclamationTriangle, faTrash, faSpinner);

if(location.host.indexOf('project')>-1) axios.defaults.baseURL = 'http://project-fmm:8000'
else axios.defaults.baseURL = 'https://api.fmm-collective.fr'

const app = createApp(App).use(i18n).use(store).use(router, axios).component("font-awesome-icon", FontAwesomeIcon).mount('#app');
import "bootstrap/dist/js/bootstrap.js"

